import { Link } from '@remix-run/react'
import { motion } from 'framer-motion'
import { useEffect, useRef, useState } from 'react'
import IcOutlineNotes from '~icons/ic/outline-notes'

export const menu = [
  {
    label: 'برنامه ورزشی',
    href: '#M4',
  },
  {
    label: 'فروشگاه',
    href: 'https://shop.marian-sports.com',
  },
  {
    label: 'دوره ها',
    href: '/',
  },
  {
    label: 'کلاس های آنلاین',
    href: '/',
  },
  {
    label: 'مقالات تخصصی',
    href: '/',
  },
  {
    label: 'درباره ما',
    href: '#M2',
  },
  {
    label: 'ارتباط با ما',
    href: '#M5',
  },
  {
    label: 'ورود / عضویت',
    href: '/login',
  },
]

export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false)

  const headerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const scrollListener = () => {
      if (window.scrollY > 300) {
        if (!headerRef.current?.classList.contains('sticky-header')) headerRef.current?.classList.add('sticky-header')
      } else {
        if (headerRef.current?.classList.contains('sticky-header')) headerRef.current?.classList.remove('sticky-header')
      }
    }
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, [])

  const toggleNavbar = setIsOpen.bind(null, !isOpen)

  return (
    <>
      {isOpen && (
        <motion.div
          className="fixed z-50 pt-[100px] px-4 text-white bg-black top-0 right-0 w-full h-full"
          initial={{
            opacity: 0,
            x: 100,
          }}
          animate={{
            opacity: 1,
            x: 0,
          }}
          transition={{
            ease: 'easeOut',
            duration: 0.2,
          }}
        >
          <ul className="flex-col flex gap-8">
            {menu.map(item => {
              return (
                <li key={item.label}>
                  <Link className="block w-full" to={item.href} onClick={toggleNavbar}>
                    {item.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </motion.div>
      )}
      <header className="site-header" ref={headerRef}>
        <div className="header-bottom">
          <div className="container mx-auto sm:px-4">
            <div className="flex items-center gap-8 xl:gap-8 justify-center w-full">
              <div className="header-menu w-full flex-grow flex flex-col justify-center items-center">
                <nav className="main-navigation one w-full">
                  <div className="flex items-center justify-between">
                    <Link to="/" title="Marian" className="max-w-[150px] lg:hidden">
                      <img src="/images/logowhite.PNG" alt="Logo" className="sticky w-[150px]" />
                    </Link>
                    <button className="toggle-button text-white" onClick={toggleNavbar}>
                      <IcOutlineNotes />
                    </button>
                  </div>
                  <div className="mobile-menu-box">
                    <i className="menu-background top"></i>
                    <i className="menu-background middle"></i>
                    <i className="menu-background bottom"></i>
                    <ul className="menu grid grid-cols-10 justify-between w-full items-center mt-4">
                      {menu.slice(0, Math.ceil(menu.length / 2)).map(item => {
                        return (
                          <li key={item.label} className="text-center ">
                            <Link to={item.href}>{item.label}</Link>
                          </li>
                        )
                      })}
                      <Link to="/" title="Marian" className="centered-logo col-span-2 flex justify-center">
                        <img src="/images/marian-centered-white.png" className="w-[130px]" alt="Logo" />
                        <img src="/images/logowhite.PNG" className="sticky hidden w-[200px]" alt="Logo" />
                      </Link>
                      {menu.slice(Math.ceil(menu.length / 2), menu.length).map(item => {
                        return (
                          <li key={item.label} className="text-center ">
                            <Link to={item.href}>{item.label}</Link>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                </nav>
                <div className="black-shadow"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}
